import React, { ReactElement } from "react";

import { FooterData } from "data/footer";

import * as styles from "./FooterLinks.css";
import { FooterLinksColumn } from "./FooterLinksColumn";

export const FooterLinks = ({ data }: { data: FooterData }): ReactElement => {
  return (
    <div className={styles.container}>
      {data?.columns.map((col) => (
        <div key={col.title} className={styles.column}>
          <div className={styles.title}>{col.title}</div>
          <FooterLinksColumn links={col.links} />
        </div>
      ))}
    </div>
  );
};
