import React, { MouseEvent } from "react";

import Link from "next/link";

import { FooterLink } from "data/footer";
import {
  ClickableContentWrapper,
  Link as StyledLink,
  TextWithHighlights,
} from "studio-design-system";

import { useIntercomFeature } from "~/hooks/support/useIntercomFeature";

import * as styles from "./FooterLinksColumn.css";

interface Props {
  links: FooterLink[];
}

export const FooterLinksColumn = ({ links }: Props): React.ReactElement => {
  const { onQuestionClick } = useIntercomFeature();

  const actions: Record<string, () => void> = {
    onQuestionClick,
  };
  const onClick = (event: MouseEvent<HTMLAnchorElement>, link: FooterLink) => {
    if (link.action && actions[link.action]) {
      event.preventDefault();
      actions[link.action]();
    }
  };

  const content = ({ label }: { label: string }) => (
    <TextWithHighlights highlightVariant="textColor" className={styles.link}>
      {label}
    </TextWithHighlights>
  );

  return (
    <>
      {links.map((link, index) => (
        <React.Fragment key={index}>
          {link.external && (
            <StyledLink url={link.url} accessibilityLabel={link.label} external={link.external}>
              <TextWithHighlights highlightVariant="textColor" className={styles.link}>
                {link.label}
              </TextWithHighlights>
            </StyledLink>
          )}
          {!link.external && link.url && (
            <Link href={link.url} aria-label={link.label}>
              <a>
                <TextWithHighlights highlightVariant="textColor" className={styles.link}>
                  {link.label}
                </TextWithHighlights>
              </a>
            </Link>
          )}
          {!link.external && link.action && (
            <ClickableContentWrapper>
              <a onClick={(e) => onClick(e, link)} aria-label={link.label}>
                <TextWithHighlights highlightVariant="textColor" className={styles.link}>
                  {link.label}
                </TextWithHighlights>
              </a>
            </ClickableContentWrapper>
          )}
        </React.Fragment>
      ))}
    </>
  );
};
