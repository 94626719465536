import React, { ReactElement } from "react";

import { Logo } from "studio-design-system";

import * as styles from "./FooterLogo.css";

export const FooterLogo = (): ReactElement => (
  <div className={styles.logo}>
    <Logo theme="dark" size="medium" />
    <div className={styles.copyrightContainer}>
      <span className={styles.copyright}>
        <span className={styles.copyrightSymbol}>© </span>
        {new Date().getFullYear()}
      </span>
    </div>
  </div>
);
